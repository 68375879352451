<template>
  <div>
    <el-page-header :content="$t('fence.device')+' ['+imei+'] '+$t('index.positioning')" @back="goBack"
                    style="margin-bottom:10px"></el-page-header>
    <el-card class="box-card">
      <div class="mapcont" style="position:relative;">
        <div class="map container" id="container" style="width:100%;min-height:550px;">
          <div class="deviceMes">
            <p>
              <span>{{ $t('index.device_type') }}</span> {{ type }}
            </p>
            <p>
              <span>设备IMEI</span> {{ imei }}
            </p>
            <p>
              <span>{{ $t('index.location_info') }}</span> {{ location }}
            </p>
            <p>
              <span>{{ $t('detail.position_method') }}</span> {{ location_type }}
            </p>
            <p>
              <span>{{ $t('index.battery_level') }}</span> {{ volt }}%
            </p>
            <p>
              <span>{{ $t('device.position_time') }}</span> {{ location_time }}
            </p>
            <p>
              <span>{{ $t('index.comm_time') }}</span> {{ update_at }}
            </p>
          </div>
        </div>
      </div>
    </el-card>
    <locator/>
  </div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import device from "../../model/device";
import Locator from "@/components/locator.vue";

export default {
  name: "dataLocation",
  components: {
    Locator
  },
  data() {
    return {
      type: '',
      imei: this.$route.query.imei,
      longitude: '',
      latitude: '',
      location: '',
      location_type: '',
      volt: '',
      is_online: '',
      location_time: '',
      update_at: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadDevice()
    })
  },
  methods: {
    async loadDevice() {
      const ret = await device.info({imei: this.imei})
      if (ret.code === 200) {
        if (!ret.data.longitude || !ret.data.latitude) {
          this.$message.warning(this.$t('detail.location_failed'))
          return false
        }
        this.longitude = ret.data.longitude
        this.latitude = ret.data.latitude
        this.location = ret.data.location
        this.location_type = ret.data.location_type
        this.is_online = ret.data.is_online
        this.type = ret.data.type
        this.volt = ret.data.volt
        this.location_time = ret.data.location_time
        this.update_at = ret.data.update_at
        this.loadMap()
      } else {
        this.$message.error(ret.message)
      }
    },
    loadMap() {
      AMapLoader.load({
        key: this.$store.state.amap_key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: this.$store.state.amap_version, // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [] //插件列表
      })
          .then(AMap => {
            let map = new AMap.Map("container", {
              center: [this.longitude, this.latitude],
              zoom: 13
            });
            let trafficLayer = new AMap.TileLayer.Traffic({
              zIndex: 2
            });
            let marker = new AMap.Marker({
              icon: this.is_online !== 1 ? this.$store.state.outline : this.$store.state.online,
              position: [this.longitude, this.latitude],
              title: this.location
            });
            // 将以上覆盖物添加到地图上
            // 单独将点标记添加到地图上
            map.add(marker);
            map.add(trafficLayer); //添加图层到地图
          })
          .catch(e => {
            console.log(e);
          });
    },
    goBack() {
      this.$router.replace({
        path: "/data/home",
        query: {
          imei: this.imei
        }
      })
    }
  }
};
</script>
<style scoped>
.deviceMes {
  position: absolute;
  right: 0;
  top: 0px;
  background: rgba(255, 255, 255, 0.8);
  width: auto;
  padding: 20px 20px 10px 0px;
  height: 200px;
  z-index: 999;
  padding-top: 15px;
}

.deviceMes p {
  line-height: 30px;
  color: #666;
}

.deviceMes p span {
  display: inline-block;
  width: 80px;
  margin-right: 20px;
  text-align: right;
  color: #333;
}
</style>
