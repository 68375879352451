<template>
  <div style="margin-top: 20px;">
    <el-card class="box-carerd">
      <template #header>
        <div class="card-header">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" ref="searchForm">
            <el-form-item label="定位类型" prop="type">
              <el-select placeholder="定位类型" v-model="formInline.type">
                <el-option label="GPS" value="1"></el-option>
                <el-option label="基站" value="2"></el-option>
                <el-option label="WIFI" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button @click="searchBtn" type="primary">查询</el-button>
              <el-button @click="resetBtn" type="primary">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <el-table :data="store" border stripe style="width: 100%">
        <el-table-column label="经度" prop="longitude"></el-table-column>
        <el-table-column label="纬度" prop="latitude"></el-table-column>
        <el-table-column label="定位方式" prop="type_title"></el-table-column>
        <el-table-column label="位置详情" prop="location"></el-table-column>
        <el-table-column label="定位时间" prop="create_at"></el-table-column>
        <el-table-column label="操作" width="100">
          <template #default="scope">
            <el-button @click="handleEdit(scope.row)" size="small">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog center title="定位详情" top="10vh" v-model="mapPop" width="50%">
        <div id="map" style="width:100%;height:400px;"></div>
        <div class="alarmInfo">
          <p>
            <span>定位方式 :</span>
            {{ info.type_title }}
          </p>
          <p>
            <span>定位时间 :</span>
            {{ info.create_at }}
          </p>
          <p>
            <span>经度 :</span>
            {{ info.longitude }}
          </p>
          <p>
            <span>纬度 :</span>
            {{ info.latitude }}
          </p>
          <p>
            <span>位置详情 :</span>
            {{ info.location }}
          </p>
        </div>
      </el-dialog>
      <el-pagination
          :page-size="formInline.size"
          :page-sizes="[10, 20, 50]"
          :total="pageTotal"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-card>
  </div>
</template>
<script>
import data from "@/model/data";
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  name: "locator",
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      formInline: {
        type: '',
        imei: this.$route.query.imei,
        page: 1,
        size: 10
      },
      info: {},
      mapPop: false,
      store: []
    }
  },
  mounted() {
    setTimeout(() => {
      this.loadData();
    }, 500)
  },
  methods: {
    async loadData() {
      const ret = await data.locator(this.formInline);
      if (ret.code === 200) {
        this.store = ret.data;
        this.pageTotal = ret.count;
      }
    },
    handleEdit(data) {
      this.info = data;
      this.mapPop = true;
      AMapLoader.load({
        key: this.$store.state.amap_key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: this.$store.state.amap_version, // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [] //插件列表
      })
          .then(AMap => {
            let map = new AMap.Map("map", {
              center: [data.longitude, data.latitude],
              zoom: 13
            });
            let trafficLayer = new AMap.TileLayer.Traffic({
              zIndex: 2
            });
            let marker = new AMap.Marker({
              icon: this.$store.state.alarm,
              position: [data.longitude, data.latitude],
              title: data.location
            });
            // 将以上覆盖物添加到地图上
            // 单独将点标记添加到地图上
            map.add(marker);
            map.add(trafficLayer); //添加图层到地图
          })
          .catch(e => {
            console.log(e);
          });
    },
    searchBtn() {
      this.loadData();
    },
    resetBtn() {
      this.formInline.type = "";
      this.loadData();
    },
    handleSizeChange(val) {
      this.formInline.size = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.formInline.page = val;
      this.loadData();
    }
  }
};
</script>
<style scoped>
.demo-form-inline {
  margin-top: 20px;
}

.el-pagination {
  margin: 30px 0px;
  float: right;
}

.alarmInfo {
  margin-top: 20px;
}

.alarmInfo p {
  line-height: 25px;
  letter-spacing: 2px;
}
</style>
